var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moments-template"},[(_vm.menuInfo.components)?_c('div',{staticClass:"moments-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"moments-list-item"},[_c('img',{staticClass:"moments-list-item-img",attrs:{"src":item.image_url}}),(index === 0)?_c('div',{staticClass:"moments-list-item-img-filter"},[_c('img',{attrs:{"src":require("@/common/images/live.gif"),"alt":""}})]):_vm._e(),_c('div',{class:{
          'moments-list-item-text': true,
          active: index === 0
        }},[_vm._v(" "+_vm._s(item.subject)+" ")]),_c('div',{class:{
          'moments-list-item-time': true,
          active: index === 0
        }},[_c('vh-icon',{staticClass:"vh-icon-time"}),_vm._v(" "+_vm._s(_vm._f("timeF")(item.start_time))+" ")],1)])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }