<template>
  <div class="moments-template">
    <div class="moments-list" v-if="menuInfo.components">
      <div class="moments-list-item" v-for="(item, index) in list" :key="item.id">
        <img class="moments-list-item-img" :src="item.image_url" />
        <div v-if="index === 0" class="moments-list-item-img-filter">
          <img src="@/common/images/live.gif" alt="" />
        </div>
        <div
          :class="{
            'moments-list-item-text': true,
            active: index === 0
          }"
        >
          {{ item.subject }}
        </div>
        <div
          :class="{
            'moments-list-item-time': true,
            active: index === 0
          }"
        >
          <vh-icon class="vh-icon-time"></vh-icon>
          {{ item.start_time | timeF }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const staticList = [
    {
      start_time: 1000 * 30,
      subject: '通过数字人技术形成，虚拟人物可以进行视频播报。',
      image_url: require('../../../../../common/images/custom-tab/moments-default.png')
    },
    {
      start_time: 1000 * 45,
      subject: '一种是真人方式可录制视频制作数字人，另一种则是虚拟方式...',
      image_url: require('../../../../../common/images/custom-tab/moments-default.png')
    },
    {
      start_time: 1000 * 90,
      subject: '播报型数字人对短视频内容进行讲解。',
      image_url: require('../../../../../common/images/custom-tab/moments-default.png')
    }
  ];
  export default {
    props: {
      menuInfo: {
        required: false
      }
    },
    name: 'component-moments',
    data() {
      return {
        file_default: '//cnstatic01.e.vhall.com/common-static/images/file_default.png'
      };
    },
    computed: {
      list() {
        return this.menuInfo.components.data.length > 0
          ? this.menuInfo.components.data
          : staticList;
      }
    },
    filters: {
      timeF(val) {
        const h = Math.floor(val / 1000 / 60 / 60);
        const m = Math.floor((val / 1000 / 60) % 60);
        const s = Math.floor((val / 1000) % 60);
        return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s} `;
      }
    }
  };
</script>

<style lang="less" scoped>
  .moments-template {
    padding: 0 8px 0 12px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;

    .moments-list {
      &-item {
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        background: #f5f5f5;
        margin-bottom: 8px;
        position: relative;
        padding: 9px 8px 9px 111px;
        border-radius: 4px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 95px;
          height: 51px;
          display: block;
          position: absolute;
          left: 8px;
          top: 9px;
        }
        &-img-filter {
          width: 95px;
          height: 51px;
          position: absolute;
          left: 8px;
          top: 9px;
          z-index: 1;
          background: rgba(0, 0, 0, 0.6);
          font-size: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;

          img {
            width: 12px;
            height: 9px;
            display: block;
          }
        }

        &-text {
          width: 100%;
          height: 36px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          font-weight: 400;
          color: #262626;
          line-height: 18px;

          &.active {
            color: #fb2626;
          }
        }

        &-time {
          height: 17px;
          display: flex;
          align-items: center;
          font-size: 10px;
          font-weight: 400;
          color: #595959;

          .vh-icon-time {
            margin-right: 2.5px;
          }

          &.active {
            color: #fb2626;
          }
        }
      }
    }
  }
</style>
